import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';

export interface AlertProps {
  id?: string;
  children: ReactNode;
  onClose?: () => void;
  className?: string;
  classNameIcon?: string;
  type?: 'success' | 'error' | 'warning' | 'info' | 'loading';
}

export const Alert = ({ className, classNameIcon, id, children, onClose, type = 'error' }: AlertProps) => {
  return (
    <div
      className={classNames(
        'alert rounded-md p-4',
        type === 'error' && 'bg-red-50',
        type === 'success' && 'bg-green-50',
        type === 'warning' && 'bg-orange-50',
        type === 'info' && 'bg-blue-50',
        type === 'loading' && 'bg-blue-50',
        className,
      )}
      id={id}
    >
      <div className="flex select-none items-center">
        <div className="flex-shrink-0">
          <i
            className={classNames(
              type === 'error' && 'fas fa-exclamation-circle text-red-400',
              type === 'success' && 'fas fa-check-circle text-green-400',
              type === 'warning' && 'fas fa-exclamation-triangle text-orange-400',
              type === 'info' && 'fas fa-info-circle text-blue-400',
              type === 'loading' && 'fad fa-spinner text-blue-400',
              classNameIcon,
            )}
            aria-hidden="true"
          />
        </div>
        <div
          className={classNames(
            'ml-3 text-sm',
            type === 'error' && 'text-red-700',
            type === 'success' && 'text-green-700',
            type === 'warning' && 'text-orange-700',
            type === 'info' && 'text-blue-700',
            type === 'loading' && 'text-blue-700',
          )}
        >
          {children}
        </div>
        {onClose && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onClose}
                className={classNames(
                  'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
                  type === 'success' &&
                    'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
                  type === 'error' &&
                    'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
                )}
              >
                <span className="sr-only">Dismiss</span>
                <i className="fa-solid fa-times" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
