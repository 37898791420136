import { ReactNode } from 'react';

interface Props {
  htmlFor: string;
  children: ReactNode;
}

export const FormLabel = ({ children, htmlFor }: Props) => {
  return (
    <label htmlFor={htmlFor} className="psc-text block select-none text-sm font-medium">
      {children}
    </label>
  );
};
