import { ReactNode } from 'react';
import { Menu } from '@headlessui/react';
import { classNames } from '../../helpers/classNames';
import { useRecoilValue } from 'recoil';
import { CurrentUserAtom } from '../../atoms/current-user.atom';
import { checkUserHasAnyRole } from '../../../common/helpers/auth.helpers';
import { UppercaseFirstLetter } from '../../../common/types/text.types';

export interface NavIconLinkProps {
  className?: string;
  children: ReactNode;
  icon: string;
  href: string;
  openInNewTab?: boolean;
  color: 'orange' | 'blue' | 'violet' | 'green' | 'lime' | 'gray';
  requiredRole?: UppercaseFirstLetter;
}

export const NavIconLink = ({
  className,
  children,
  openInNewTab,
  color,
  icon,
  href,
  requiredRole,
}: NavIconLinkProps) => {
  const currentUser = useRecoilValue(CurrentUserAtom);
  if (requiredRole && !checkUserHasAnyRole(currentUser, [requiredRole])) {
    return null;
  }

  return (
    <Menu.Item>
      <a
        href={href}
        rel="noopener noreferrer"
        target={openInNewTab ? '_blank' : undefined}
        className={`flex cursor-pointer items-center px-psc-base py-psc-sm pl-psc-base hover:bg-gray-100 ${className}`}
      >
        <div
          className={classNames(
            'flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-md text-xs text-white',
            color === 'blue' && 'bg-blue-700',
            color === 'orange' && 'bg-orange-500',
            color === 'violet' && 'bg-violet-700',
            color === 'green' && 'bg-green-600',
            color === 'lime' && 'bg-lime-600',
            color === 'gray' && 'bg-slate-500',
          )}
        >
          <i className={`fal ${icon}`} />
        </div>
        <div className="pl-psc-sm text-sm">{children}</div>
        {openInNewTab && (
          <div className="flex-grow text-right">
            <i className="fas fa-external-link-alt ml-2 text-sm text-gray-600" />
          </div>
        )}
      </a>
    </Menu.Item>
  );
};
