import { classNames } from '../../helpers/classNames';
import { DetailedHTMLProps, ForwardedRef, forwardRef, InputHTMLAttributes, ReactElement } from 'react';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  hasError?: boolean;
  fullWidth?: boolean;
  rightButton?: ReactElement;
  rightButtonWidth?: number;
}

export const FormInput = forwardRef((props: Props, ref?: ForwardedRef<HTMLInputElement>) => {
  const { className, hasError, fullWidth, rightButton, rightButtonWidth, ...inputProps } = props;
  return (
    <div className={classNames('relative', className)}>
      <input
        ref={ref}
        className={classNames(
          'block w-full rounded-md py-2 pl-3 pr-10 focus:outline-none sm:text-sm',
          'rounded-md border border-gray-200 shadow-sm',
          hasError
            ? 'border-red-300 text-red-900 placeholder-red-400 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-300 text-gray-900 placeholder-gray-400 focus:border-blue-600 focus:ring-blue-700',
          inputProps.disabled && 'cursor-not-allowed bg-gray-100',
          fullWidth ? 'w-full' : 'w-auto',
        )}
        aria-invalid={hasError}
        aria-describedby={`${inputProps.name}-error`}
        {...inputProps}
      />
      {rightButton ? (
        <div
          className={classNames(
            'absolute inset-y-0 right-0 flex items-center pr-3',
            hasError ? 'mr-7 text-red-900' : '',
            rightButtonWidth ? ` w-[${rightButtonWidth}px]` : '',
          )}
        >
          {rightButton}
        </div>
      ) : null}
      {hasError && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <i className="fa-solid fa-warning fa-xl text-red-300" aria-hidden="true" />
        </div>
      )}
    </div>
  );
});

FormInput.displayName = 'FormInput';
