import { NavLogos } from './nav-logos';
import { NavAppsMenu } from './nav-apps-menu';
import { NavPlatformMenu } from './nav-platform-menu';
import { ReactNode } from 'react';
import { NavAppAdminsMenu } from './nav-app-admins-menu';
import { getNavAppConfig } from './nav.helpers';
import { App } from '../../../common/types/app.types';

import { AppLogo } from '../app-logo/app-logo';
import { checkUserHasAnyRole } from '../../../common/helpers/auth.helpers';
import { useRecoilValue } from 'recoil';
import { CurrentUserAtom } from '../../atoms/current-user.atom';
import { ADMIN_ROLE } from '../../../common/types/roles-permissions.types';
import { classNames } from '../../helpers/classNames';
import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';

interface Props {
  app: App;
  customLogo?: string;
  isPlatform?: boolean;
  appLinks?: ReactNode;
}

export const Nav = ({ app, isPlatform = true, appLinks, customLogo }: Props) => {
  const currentUser = useRecoilValue(CurrentUserAtom);
  const { logo, appName, url, appColor } = getNavAppConfig(app);

  const config = getMultiClientPlatformConfigForClient();
  const isUserAdmin = currentUser && checkUserHasAnyRole(currentUser, [ADMIN_ROLE]);
  const filteredRoles = currentUser?.rolesAndPermissions?.filter((role) => !!role.roleId) || [];
  const shouldShowApps = (filteredRoles.length || 0) > 0;

  return (
    <div
      className={classNames(
        'nav-bg fixed top-0 z-40 flex h-[60px] w-full items-center border-b border-gray-300 shadow',
        config.hideMainBarAppIcons ? 'pl-4 pr-8' : 'px-8',
      )}
    >
      {shouldShowApps && <NavAppsMenu />}
      <NavLogos
        customLogo={customLogo}
        logo={
          <AppLogo icon={logo} color={appColor}>
            {appName}
          </AppLogo>
        }
        url={url}
        isPlatform={isPlatform}
      />
      <div className="flex flex-grow items-center justify-end">
        {appName && appLinks && isUserAdmin ? <NavAppAdminsMenu appName={appName} links={appLinks} /> : undefined}
        <NavPlatformMenu />
      </div>
    </div>
  );
};
