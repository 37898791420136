import { classNames } from '../../helpers/classNames';
import { Fragment, ReactNode } from 'react';
import logoImage from '../../assets/ps-access-logo.svg';

interface Props {
  url?: string;
  logo: ReactNode;
  altText?: string;
  className?: string;
  customLogo?: string;
  isPlatform?: boolean;
}

export const NavLogos = ({ logo, isPlatform = false, url, className, customLogo }: Props) => {
  if (customLogo) {
    return (
      <div className={classNames('flex h-full items-center justify-center', className)}>
        <a className="mr-[10px] hidden leading-none text-slate-600 tablet:block" href={window.env.PLATFORM_URL}>
          <img src={customLogo} alt="" className="h-[33px]" />
        </a>
      </div>
    );
  }

  return (
    <div className={classNames('flex h-full items-center justify-center pl-psc-base', className)}>
      {!isPlatform ? (
        <Fragment>
          <a className="mr-[10px] hidden leading-none text-slate-600 tablet:block" href={window.env.PLATFORM_URL}>
            <img src={logoImage} alt="" className="h-[33px]" />
          </a>
          <a className="leading-none" href={url}>
            {logo}
          </a>
        </Fragment>
      ) : (
        <a className="leading-none text-slate-600" href={window.env.PLATFORM_URL}>
          <img src={logoImage} className="h-[33px]" alt="" />
        </a>
      )}
    </div>
  );
};
